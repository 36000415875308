
import { defineComponent } from "vue";
import KTInviteFriendsModal from "@/components/modals/general/InviteFriendsModal.vue";
import firebase from 'firebase'
// import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-account",
  components: {
    // Dropdown3,
    KTInviteFriendsModal
  },
  data(){
    return {
      user: null,
    }
  },
  created(){
    this.getuser()
  },
  methods: {
    async getuser(){
      try{
        let uid = this.$route.params.uid
        let { data } = await this.axios(`/users/${uid}`)
        this.user = data.user
      }catch(e){
        console.log(e)
      }
    },
    suspend(){
      this.axios.post(`/users/${this.user.status == 'suspended' ? 'activate' : 'suspend'}`, {
        uid: this.$route.params.uid
      }).then(()=>{
        this.$router.go(0)
      })
    }
  },
});
